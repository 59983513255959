<template>
  <div
    ref="mainFileDropArea"
    class="detail-version-list"
    @drop.prevent="handleMainDrop($event)"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
  >
    <div
      class="detail-version-list__add-version"
      data-testid="addVersionButton"
    >
      <ElementButton
        type="text"
        @click="addNewVersion()"
      >
        Add New Version
      </ElementButton>
    </div>
    <div class="detail-version-list__versions">
      <ElementTooltip
        v-for="version in versions"
        :key="version.id"
        ref="versionsTooltips"
        effect="dark"
        placement="bottom"
        transition="none"
        :enterable="false"
        :disabled="getRenderStatusTooltipOptions(version).disabled"
        data-testid="versionsTooltips"
      >
        <div slot="content">
          <p
            v-for="(message, index) in getRenderStatusTooltipOptions(version)
              .tooltipMessage"
            :key="index"
            ref="versionTooltip"
            data-testid="versionTooltip"
          >
            {{ message }}
          </p>
        </div>
        <div
          :ref="`version-${version.id}`"
          :class="[
            'detail-version-list__version',
            {
              'detail-version-list__version--active':
                activeVersion && version.id === activeVersion.id
            }
          ]"
          @click="handleClick(version)"
        >
          <DetailThumbnailLoader
            :loading-state="getThumbnailLoadingState(version)"
            :src="buildVersionThumbnailUrl(version)"
          />
          <div class="detail-version-list__version-contributor">
            <p class="detail-version-list__version-name">
              {{ version.name }}
            </p>
            <p
              v-if="version.contributor && version.contributor.email"
              class="detail-version-list__version-email"
            >
              {{ version.contributor.email }}
            </p>
            <p
              v-if="version.createdAt"
              class="detail-version-list__version-date"
            >
              {{ formatDateShort(version.createdAt) }} . Rendered with
              {{ RENDER_ENGINE_TYPE[version.renderEngine] }}
            </p>
          </div>
          <ElementTooltip
            placement="bottom"
            :content="tooltipMessage()"
          >
            <div
              v-if="version.isMain && !version.isUploading"
              :class="[
                'detail-version-list__version-main-check',
                'el-icon-circle-check'
              ]"
            />
          </ElementTooltip>
          <StitchDropdown
            v-if="!version.isUploading"
            :placement="DROPDOWN_PLACEMENT.BOTTOM_END"
            :dropdown-items="getDropdownItems(version)"
            :icon-type="iconType"
            :append-to-body="false"
          />
        </div>
      </ElementTooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  ICON_TYPE,
  DROPDOWN_PLACEMENT,
  STYLE_TYPE
} from '@/constants/dropdownOptions'
import DetailThumbnailLoader, {
  THUMBNAIL_LOADING_STATE
} from '@/components/library/DetailStyle/components/DetailThumbnailLoader'
import VueTypes from 'vue-types'
import { BlockVersionShape } from '@/types'

// s3Trim
import { DataUtils } from '@/mixins/utils.js'
import { IMAGE_RESOLUTION } from '@/constants/image'

import Utils, { formatToLocaleDateShort } from '@/services/utils'
import { JOB_STATUS } from '@/constants/loadingStatus'
import { RENDER_ENGINE_TYPE } from '@/constants/filterType'

export const TOOLTIP_MESSAGE = {
  [JOB_STATUS.PENDING]: [
    'Lots of people are rendering...',
    'This version is waiting for its turn 😴'
  ],
  [JOB_STATUS.STARTED]: ["It's rendering! Ready soon ⏳"],
  [JOB_STATUS.FAILED]: [
    'Render failed 😔',
    'Add another version to try again.'
  ],
  VERSION_EMPTY: ['This version has no colorways'],
  MAIN_VERSION: 'This is your main version'
}

export default {
  name: 'DetailVersionList',

  components: {
    DetailThumbnailLoader
  },

  mixins: [DataUtils],

  props: {
    activeVersion: BlockVersionShape.loose,
    versions: VueTypes.arrayOf(BlockVersionShape.loose).isRequired,
    canEdit: VueTypes.bool.def(false)
  },

  data () {
    return {
      validVersionExtensions: ['.bw', '.zprj'],
      fileExtension: null,
      pollingTiming: 5000,
      DROPDOWN_PLACEMENT,
      RENDER_ENGINE_TYPE
    }
  },

  computed: {
    ...mapGetters(['getItemDetail', 'getVersionThumbnail']),

    /**
     * @returns {object}
     */
    item () {
      return this.getItemDetail()
    },

    /**
     * @returns {string}
     */
    iconType () {
      return ICON_TYPE.MENU
    },

    /**
     * @returns {boolean}
     */
    hasModelFile () {
      return this.activeVersion && this.activeVersion.model !== null
    }
  },

  watch: {
    /**
     */
    versions () {
      this.initializePolling()
    }
  },

  created () {
    this.initializePolling()
  },

  beforeDestroy () {
    clearInterval(this.autorendersStatusPolling)
  },

  methods: {
    /**
     * @param   {Date}   date
     *
     * @returns {string}
     */
    formatDateShort (date) {
      return formatToLocaleDateShort(date)
    },

    /**
     * @param {BlockVersionShape} version
     */
    handleClick (version) {
      if (this.activeVersion && version.id === this.activeVersion.id) {
        return
      }

      this.$emit('select-version', { version })
    },

    /**
     * @param {BlockVersionShape} [version]
     */
    addNewVersion (version) {
      this.$emit('add-new-version', version)
    },

    /**
     * @param {number} blockId
     * @param {number} versionId
     */
    setMainVersion (blockId, versionId) {
      this.$emit('set-main-version', {
        blockId,
        versionId
      })
    },

    /**
     * @param {number} blockId
     * @param {number} versionId
     * @param {string} versionName
     */
    deleteVersion (blockId, versionId, versionName) {
      this.$confirm(
        `This will permanently delete ${versionName}. Continue?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          this.$emit('delete-version', {
            blockId,
            versionId,
            versionName
          })
        })
        .catch(() => {})
    },

    /**
     * @param   {BlockVersionShape} version
     *
     * @returns {Array}
     */
    getDropdownItems (version) {
      const { id, blockId, name, zipUrl, modelUrl } = version

      return [
        {
          name: 'Make Main Version',
          show: !version.isMain && this.canEdit,
          method: () => this.setMainVersion(blockId, id)
        },
        {
          name: 'Download 3D File',
          show: true,
          disabled: !this.hasModelFile,
          method: () =>
            this.$emit('trigger-download', {
              url: modelUrl
            })
        },
        {
          name: 'Download All Images',
          show: true,
          disabled: !this.hasImages(version),
          method: () =>
            this.$emit('trigger-download', {
              url: zipUrl
            })
        },
        {
          name: 'Rename',
          show: this.canEdit,
          method: () =>
            this.$emit('rename-version', {
              id,
              name
            })
        },
        {
          name: 'Delete',
          show: this.canEdit,
          styles: [STYLE_TYPE.DANGER],
          method: () => this.deleteVersion(blockId, id, name)
        }
      ]
    },

    /**
     */
    initializePolling () {
      this.pollAutoRenderStatus()

      clearInterval(this.autorendersStatusPolling)
      this.autorendersStatusPolling = setInterval(() => {
        this.pollAutoRenderStatus()
      }, this.pollingTiming)
    },

    /**
     */
    pollAutoRenderStatus () {
      const versionsToPoll = this.versions.filter(
        version =>
          version.autorenderStatus !== JOB_STATUS.SUCCESS &&
          version.autorenderStatus !== JOB_STATUS.FAILED &&
          !version.isUploading &&
          version.renderJobId !== null &&
          version.renderJobId !== undefined
      )

      if (versionsToPoll.length === 0) {
        clearInterval(this.autorendersStatusPolling)
        this.autorendersStatusPolling = null

        return
      }

      const versionsId = versionsToPoll.map(version => version.renderJobId)

      this.$emit('poll-versions-render-status', versionsId)
    },

    /**
     * @param   {BlockVersionShape} version
     *
     * @returns {object}
     */
    getRenderStatusTooltipOptions (version) {
      let tooltipMessage

      if (
        version.autorenderStatus &&
        version.autorenderStatus !== JOB_STATUS.SUCCESS
      ) {
        tooltipMessage = TOOLTIP_MESSAGE[version.autorenderStatus]
      } else if (this.isVersionEmpty(version)) {
        tooltipMessage = TOOLTIP_MESSAGE.VERSION_EMPTY
      }

      return {
        disabled: !tooltipMessage,
        tooltipMessage
      }
    },

    /**
     * @returns {string}
     */
    tooltipMessage () {
      return TOOLTIP_MESSAGE.MAIN_VERSION
    },

    /**
     * @param   {BlockVersionShape} version
     *
     * @returns {boolean}
     */
    isVersionEmpty (version) {
      return (
        version.isUploading || (version.options && version.options.length === 0)
      )
    },

    /**
     * @param   {BlockVersionShape} version
     *
     * @returns {string}
     */
    buildVersionThumbnailUrl (version) {
      if (!this.hasImages(version)) {
        return null
      }

      const thumbnail = version.views.find(view => view.isThumbnail)

      if (thumbnail) {
        return this.s3trim(thumbnail.fileUrl, IMAGE_RESOLUTION.THUMBNAIL)
      }
    },

    /**
     * @param   {BlockVersionShape} version
     *
     * @returns {boolean}
     */
    hasImages (version) {
      if (!version.views) {
        return false
      }

      return version.views.length > 0
    },

    /**
     * @param   {BlockVersionShape} version
     *
     * @returns {string}
     */
    getThumbnailLoadingState (version) {
      const { isUploading, autorenderStatus } = version

      if (isUploading) {
        return THUMBNAIL_LOADING_STATE.UPLOADING
      }

      if (
        autorenderStatus === JOB_STATUS.SUCCESS ||
        autorenderStatus === JOB_STATUS.FAILED ||
        autorenderStatus === null
      ) {
        return THUMBNAIL_LOADING_STATE.COMPLETE
      }

      return THUMBNAIL_LOADING_STATE.RENDERING
    },

    /**
     * @param {Event} event
     */
    handleMainDrop (event) {
      this.dragLeave()

      const isFile = event.dataTransfer.types.includes('Files')

      if (isFile) {
        const validFiles = Utils.getValidFilesFromEvent(
          event,
          this.validVersionExtensions
        )

        if (validFiles.length === 0) {
          return
        }

        const newItemFile = validFiles[0]
        const name = Utils.getFileNameWithoutExtension(newItemFile.name)

        const defaultDataForVersion = {
          model3D: [
            {
              name,
              description: name,
              assets_url: newItemFile
            }
          ]
        }
        this.addNewVersion(defaultDataForVersion)
      }
    },

    /**
     */
    dragOver () {
      const mainFileDropArea = this.$refs.mainFileDropArea
      mainFileDropArea.classList.add('dragging')
    },

    /**
     */
    dragLeave () {
      const mainFileDropArea = this.$refs.mainFileDropArea
      mainFileDropArea.classList.remove('dragging')
    }
  }
}
</script>

<style lang="scss" scoped>
$version-list-width: 40%;
$checkmark-font-size: 20px;

.detail-version-list {
  display: flex;
  flex-direction: column;
  width: $version-list-width;
  min-width: spacing(30);
  max-width: spacing(60);
  overflow: hidden;
  border-right: $border-divider;
}

.detail-version-list__add-version {
  padding: 0 spacing(4);
  border-bottom: $border-divider;
}

.detail-version-list__versions {
  @include scroll-y-smooth;
}

.detail-version-list__version {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(2) spacing(3);
  border-bottom: $border-divider;
  cursor: pointer;

  &:hover,
  &.detail-version-list__version--active {
    background-color: $blue-light;
  }
}

.detail-version-list__version-contributor {
  @include text-ellipsis;

  flex: 1;
  margin-right: spacing(1);
}

.detail-version-list__version-name,
.detail-version-list__version-email {
  @include text-ellipsis;
}

.detail-version-list__version-email,
.detail-version-list__version-date {
  @include text-label;
}

.detail-version-list__version-email {
  color: $grey;
}

.detail-version-list__version-date {
  color: $grey-light;
}

.detail-version-list__version-main-check {
  margin-right: spacing(1);
  font-size: $checkmark-font-size;
}

.dragging {
  background-color: $blue-light;
}
</style>
