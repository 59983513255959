/*
In order to add a new component:
- look into ./node_modules/element-ui/lib for the right package and import it here
- add it to the "components" array
- add it to the export at the end of the file
*/
import ElementAlert from 'element-ui/lib/alert'
import ElementAvatar from 'element-ui/lib/avatar'
import ElementAutocomplete from 'element-ui/lib/autocomplete'
import ElementBadge from 'element-ui/lib/badge'
import ElementButton from 'element-ui/lib/button'
import ElementCard from 'element-ui/lib/card'
import ElementCheckbox from 'element-ui/lib/checkbox'
import ElementCheckboxGroup from 'element-ui/lib/checkbox-group'
import ElementClickOutside from 'element-ui/src/utils/clickoutside'
import ElementCollapse from 'element-ui/lib/collapse'
import ElementCollapseItem from 'element-ui/lib/collapse-item'
import ElementDialog from 'element-ui/lib/dialog'
import ElementDivider from 'element-ui/lib/divider'
import ElementDropdown from 'element-ui/lib/dropdown'
import ElementDrawer from 'element-ui/lib/drawer'
import ElementDropdownItem from 'element-ui/lib/dropdown-item'
import ElementDropdownMenu from 'element-ui/lib/dropdown-menu'
import ElementForm from 'element-ui/lib/form'
import ElementFormItem from 'element-ui/lib/form-item'
import ElementInfiniteScroll from 'element-ui/lib/infinite-scroll'
import ElementInput from 'element-ui/lib/input'
import ElementLink from 'element-ui/lib/link'
import ElementLoading from 'element-ui/lib/loading'
import ElementMessage from 'element-ui/lib/message'
import ElementMessageBox from 'element-ui/lib/message-box'
import ElementNotification from 'element-ui/lib/notification'
import ElementOption from 'element-ui/lib/option'
import ElementPopover from 'element-ui/lib/popover'
import ElementRadioGroup from 'element-ui/lib/radio-group'
import ElementRadioButton from 'element-ui/lib/radio-button'
import ElementSelect from 'element-ui/lib/select'
import ElementSpinner from 'element-ui/lib/spinner'
import ElementSwitch from 'element-ui/lib/switch'
import ElementStep from 'element-ui/lib/step'
import ElementSteps from 'element-ui/lib/steps'
import ElementTabPane from 'element-ui/lib/tab-pane'
import ElementTabs from 'element-ui/lib/tabs'
import ElementTable from 'element-ui/lib/table'
import ElementTableColumn from 'element-ui/lib/table-column'
import ElementTag from 'element-ui/lib/tag'
import ElementTooltip from 'element-ui/lib/tooltip'
import ElementUpload from 'element-ui/lib/upload'
import ElementCascader from 'element-ui/lib/cascader'
import ElementCascaderPanel from 'element-ui/lib/cascader-panel'
import ElementPagination from 'element-ui/lib/pagination'

import locale from 'element-ui/lib/locale'
import localeEn from 'element-ui/lib/locale/lang/en'

const components = [
  ElementAlert,
  ElementAvatar,
  ElementAutocomplete,
  ElementBadge,
  ElementButton,
  ElementCard,
  ElementCheckbox,
  ElementCheckboxGroup,
  ElementCollapse,
  ElementCollapseItem,
  ElementDialog,
  ElementDivider,
  ElementDrawer,
  ElementDropdown,
  ElementDropdownItem,
  ElementDropdownMenu,
  ElementForm,
  ElementFormItem,
  ElementInput,
  ElementLink,
  ElementOption,
  ElementPopover,
  ElementRadioGroup,
  ElementRadioButton,
  ElementSelect,
  ElementSpinner,
  ElementSwitch,
  ElementStep,
  ElementSteps,
  ElementTabPane,
  ElementTabs,
  ElementTable,
  ElementTableColumn,
  ElementTag,
  ElementTooltip,
  ElementUpload,
  ElementCascader,
  ElementCascaderPanel,
  ElementPagination
]

/**
 * @param {Vue} Vue
 */
const install = Vue => {
  locale.use(localeEn)

  /*
   * When set to "true", this setting adds a right padding on body to hide the scroll bar.
   * Since in our structure body is never scrollable the padding just shifts the whole screen to the right.
   */
  ElementDialog.props.lockScroll = false
  components.forEach(component => {
    const name = component.name.replace(/^.{2}/g, 'Element')
    Vue.component(name, component)
  })

  Vue.directive('click-outside', ElementClickOutside)
  Vue.use(ElementInfiniteScroll)
  Vue.use(ElementLoading)

  Vue.prototype.$msgbox = ElementMessageBox
  Vue.prototype.$alert = ElementMessageBox.alert
  Vue.prototype.$confirm = (message, title, options) =>
    ElementMessageBox.confirm(message, title, {
      ...options,
      lockScroll: false
    })
  Vue.prototype.$prompt = ElementMessageBox.prompt
  Vue.prototype.$notify = ElementNotification
  Vue.prototype.$message = ElementMessage
}

export {
  ElementAlert,
  ElementAvatar,
  ElementAutocomplete,
  ElementBadge,
  ElementButton,
  ElementCard,
  ElementCheckbox,
  ElementCheckboxGroup,
  ElementCollapse,
  ElementCollapseItem,
  ElementDialog,
  ElementDivider,
  ElementDropdown,
  ElementDropdownItem,
  ElementDropdownMenu,
  ElementForm,
  ElementFormItem,
  ElementInput,
  ElementLink,
  ElementOption,
  ElementPopover,
  ElementRadioGroup,
  ElementRadioButton,
  ElementSelect,
  ElementSpinner,
  ElementSwitch,
  ElementStep,
  ElementSteps,
  ElementTabPane,
  ElementTabs,
  ElementTable,
  ElementTableColumn,
  ElementTag,
  ElementTooltip,
  ElementUpload,
  ElementCascaderPanel,
  ElementPagination
}

export default {
  install
}
