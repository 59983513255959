// Types and Platforms are in correspondance with the ones in the database.

export const NOTIFICATIONS_SETTINGS_PLATFORM = Object.freeze({
  EMAIL: 'email',
  IN_APP: 'in_app'
})

export const NOTIFICATIONS_TYPE = Object.freeze({
  REMOVED_FROM_TEAM: 'removed_from_team',
  ADDED_TO_TEAM: 'added_to_team',
  ASSIGNED_AS_TEAM_ADMIN: 'assigned_as_team_admin',
  UNASSIGNED_AS_TEAM_ADMIN: 'unassigned_as_team_admin',
  STATUS_CHANGED: 'status_changed',
  COMMENT_ADDED: 'comment_added',
  USER_MENTIONED: 'user_mentioned',
  RENDER_JOB_COMPLETED: 'render_job_completed',
  DAILY_SUMMARY: 'daily_summary'
})

export const NOTIFICATIONS_SECTIONS = Object.freeze({
  STATUS_AND_MENTIONS: 'Status and Mentions',
  RENDERING: 'Rendering',
  DAILY_SUMMARY: 'Daily Email Summary',
  ADMIN: 'Team Updates'
})

export const NOTIFICATIONS_DEFAULT_STATE = {
  STATUS_AND_MENTIONS: [
    {
      user_mentioned: {
        email: false,
        in_app: false,
        title: "Notify me if I'm mentioned/tagged in a style comment"
      }
    },
    {
      status_changed: {
        email: false,
        in_app: false,
        title: 'Notify me for changes in style status'
      }
    },
    {
      comment_added: {
        email: false,
        in_app: false,
        title: 'Notify me for all the style comments and replies'
      }
    }
  ],
  RENDERING: [
    {
      render_job_completed: {
        email: false,
        in_app: false,
        title: 'Notify me when my render jobs are complete'
      }
    }
  ],
  DAILY_SUMMARY: [
    {
      daily_summary: {
        email: false,
        title:
          "Get a daily email summary of all the updates you might've missed"
      }
    }
  ],
  ADMIN: [
    {
      added_to_team: {
        email: false,
        in_app: false,
        title: "Notify me when I'm added to a team"
      }
    },
    {
      removed_from_team: {
        email: false,
        in_app: false,
        title: "Notify me when I'm removed from a team"
      }
    },
    {
      assigned_as_team_admin: {
        email: false,
        in_app: false,
        title: 'Notify me when I\'m assigned as a "Team Admin"'
      }
    },
    {
      unassigned_as_team_admin: {
        email: false,
        in_app: false,
        title: 'Notify me when I\'m removed as a "Team Admin"'
      }
    }
  ]
}
