<template>
  <div class="notification-element">
    <span @click="openNotificationUrl(notification)">
      <div
        class="notification-element__detail"
        :class="{ 'notification-element__detail-read': notification.read }"
      >
        <div class="notification-element__feedback">
          <i
            v-if="!notification.read"
            :class="notificationColoredIcon(notification.notification_type)"
          />
          <i
            v-else
            :class="notificationIcon(notification.notification_type)"
          />
        </div>
        <div class="notification-element__content">
          <div class="notification-element__content-header">
            <div class="notification-element__content-title">
              <p>
                {{ notification.title }}
                <span class="notification-element__content-date">
                  {{ formatDateShort(notification.created_at) }}
                </span>
              </p>
            </div>
          </div>
          <div class="notification-element__content-details">
            <template v-if="notification.message_html">
              <div v-html="notification.message_html" />
            </template>
            <template v-else>
              <p>-</p>
            </template>
          </div>
        </div>
        <div
          v-if="!notification.read"
          class="notification-element__dot"
        >
          <span />
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { formatToLocaleDateShort } from '@/services/utils'
import { NOTIFICATIONS_TYPE } from '@/constants/notifications'

export default {
  name: 'NotificationElement',

  props: {
    notification: VueTypes.object
  },

  data () {
    return {
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
    }
  },

  methods: {
    ...mapActions(['readNotification']),

    /**
     * @param   {Date}   date
     *
     * @returns {string}
     */
    formatDateShort (date) {
      return formatToLocaleDateShort(date)
    },

    /**
     * @param {object} notification
     */
    openNotificationUrl (notification) {
      if (!this.notification.read) {
        this.readNotification({
          id: notification.id
        })
      }

      if (notification.action_url) {
        const route = this.$router.resolve({
          path: '/'
        })

        window.open(route.href + notification.action_url, '_blank')
      }
    },

    /**
     * @param   {string} type
     *
     * @returns {string}
     */
    notificationColoredIcon (type) {
      switch (type) {
        case NOTIFICATIONS_TYPE.COMMENT_ADDED:
          return 'el-icon-message-solid info'
        case NOTIFICATIONS_TYPE.STATUS_CHANGED:
          return 'el-icon-info info'
        case NOTIFICATIONS_TYPE.REMOVED_FROM_TEAM:
          return 'el-icon-remove danger'
        case NOTIFICATIONS_TYPE.ASSIGNED_AS_TEAM_ADMIN:
          return 'el-icon-success success'
        case NOTIFICATIONS_TYPE.UNASSIGNED_AS_TEAM_ADMIN:
          return 'el-icon-remove danger'
        case NOTIFICATIONS_TYPE.ADDED_TO_TEAM:
          return 'el-icon-success success'
        case NOTIFICATIONS_TYPE.USER_MENTIONED:
          return 'el-icon-message-solid info'
        default:
          return 'el-icon-info info'
      }
    },

    /**
     * @param   {string} type
     *
     * @returns {string}
     */
    notificationIcon (type) {
      switch (type) {
        case NOTIFICATIONS_TYPE.COMMENT_ADDED:
          return 'el-icon-message-solid'
        case NOTIFICATIONS_TYPE.STATUS_CHANGED:
          return 'el-icon-info'
        case NOTIFICATIONS_TYPE.REMOVED_FROM_TEAM:
          return 'el-icon-remove'
        case NOTIFICATIONS_TYPE.ASSIGNED_AS_TEAM_ADMIN:
          return 'el-icon-success'
        case NOTIFICATIONS_TYPE.UNASSIGNED_AS_TEAM_ADMIN:
          return 'el-icon-remove'
        case NOTIFICATIONS_TYPE.ADDED_TO_TEAM:
          return 'el-icon-success'
        case NOTIFICATIONS_TYPE.USER_MENTIONED:
          return 'el-icon-message-solid'
        default:
          return 'el-icon-info'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-element {
  padding: spacing(1);
}

.notification-element__detail {
  display: flex;
  margin-bottom: spacing(1);
  padding: spacing(1/2);
  overflow: hidden;
  color: $grey-dark;
  background-color: $white;
  border: 0.5px solid #fff;
  border-radius: spacing(1/2);
  transition: $transition;
}

.notification-element__detail:hover {
  background-color: $grey-ultra-light;
  border: 1.5px solid $blue;
  cursor: pointer;
}

.notification-element__detail-read {
  opacity: 0.5;
}

.notification-element__feedback {
  width: percentage(10);
  padding-top: spacing(1/2);
}

.notification-element__content {
  width: percentage(84);
}

.notification-element__content-header {
  display: flex;
  align-items: center;
}

.notification-element__content-title p {
  font-weight: $font-weight-medium;
  font-size: spacing(2);
}

.notification-element__content-date {
  @include text-label;

  color: $grey-light;
  font-size: spacing(3/2);
}

.notification-element__content-details {
  word-break: keep-all;
}

.notification-element__dot {
  width: percentage(6);
  text-align: end;
}

.notification-element__dot span {
  position: relative;
  top: spacing(-1/2);
  display: inline-block;
  width: spacing(3/4);
  height: spacing(3/4);
  margin-top: spacing(1);
  background: $blue;
  border-radius: percentage(50);
  content: '';
}

.notification-element__feedback i {
  font-size: spacing(3);
}

.notification-element__feedback .info {
  color: $blue;
}

.notification-element__feedback .danger {
  color: $red;
}

.notification-element__feedback .success {
  color: $green;
}
</style>
