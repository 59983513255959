<template>
  <div
    ref="optionDropzone"
    class="option-item"
    @drop.prevent="loadFiles"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
  >
    <div class="option-item__title">
      <div class="option-item__title-main">
        <StitchDropdown
          v-if="canEdit"
          ref="optionMenuDropdown"
          :dropdown-items="dropdownItems"
          :icon-type="ICON_TYPE.MENU"
          :placement="DROPDOWN_PLACEMENT.BOTTOM_START"
        />
        <ElementPopover
          v-if="canEdit"
          v-model="showAssetBrowser"
          :append-to-body="false"
          :visible-arrow="false"
          placement="bottom-end"
          popper-class="option-item__asset-browser"
          trigger="manual"
          width="300"
        >
          <StitchAssetBrowser
            v-if="showAssetBrowser"
            ref="assetBrowser"
            v-click-outside="closeAssetBrowser"
            @select-files="addImagesFromRenders"
          />
        </ElementPopover>
        <h2 class="option-item__name">
          {{ option.name }}
        </h2>
        <div class="option-item__tags">
          <ElementTag
            v-for="(value, index) in tagsData"
            :key="index"
            size="mini"
            type="info"
          >
            {{ value }}
          </ElementTag>
        </div>
      </div>
    </div>

    <StitchImageSlider
      :disabled="canEdit"
      :images="option.images"
    >
      <template #slide="{ imageIndex }">
        <div class="image-list__image">
          <img
            :alt="`Colorway ${option.name} image ${imageIndex}`"
            :src="
              s3trim(option.images[imageIndex].file_url, resolutionThumbnail)
            "
            :title="getImageFileName(option.images[imageIndex])"
            @click="openImageSlideshow(option.images, imageIndex)"
          >
          <template
            v-if="
              canEdit && option.images[imageIndex].view_name !== turntableView
            "
          >
            <template v-if="isMainVersion">
              <i
                v-if="option.images[imageIndex].is_thumbnail"
                class="el-icon-arrow-up image-icon image-icon--is-thumbnail"
              />
              <StitchLoader
                v-else-if="option.images[imageIndex].id === thumbnailId"
                class="image-icon image-icon--loader"
              />
              <i
                v-else
                class="el-icon-arrow-up image-icon image-icon--assign-thumbnail"
                @click="setStyleThumbnail(imageIndex)"
              />
              <i
                class="el-icon-close image-icon image-icon--delete"
                @click.once="deleteImage(option.images[imageIndex])"
              />
              <i
                class="el-icon-download image-icon image-icon--download"
                @click="handleImageDownload(imageIndex)"
              />
            </template>
          </template>
        </div>
      </template>
    </StitchImageSlider>
    <OptionSlideshow
      v-if="showImageSlideshow"
      :active-index="imageSlideshowActiveIndex"
      :image-urls="imageSlideshowUrls"
      :viewer-image-urls="slideshowImages"
      @close="closeImageSlideshow"
      @slide-change="handleSlideshowChange"
    />
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { OptionShape, VersionShape } from '@/types'
import { mapGetters } from 'vuex'
import OptionSlideshow from '../../OptionSlideshow'

// s3Trim, triggerDownload
import { DataUtils } from '@/mixins/utils'
import Utils from '@/services/utils'
import {
  IMAGE_EXTENSIONS,
  IMAGE_RESOLUTION,
  IMAGE_VIEW_ANGLE
} from '@/constants/image'
import {
  DROPDOWN_PLACEMENT,
  ICON_TYPE,
  STYLE_TYPE
} from '@/constants/dropdownOptions'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { TRACKER_EVENTS, TRACKER_OBJECTS } from '@/constants/tracker'

export default {
  name: 'OptionItem',

  components: {
    OptionSlideshow
  },

  mixins: [DataUtils],

  props: {
    option: OptionShape.loose,
    version: VersionShape.loose,
    canEdit: VueTypes.bool.def(false),
    canManageOptions: VueTypes.bool.def(false)
  },

  emits: [
    'add-images',
    'update-image',
    'delete-image',
    'update-option',
    'delete-option'
  ],

  data () {
    return {
      thumbnailExtensions: IMAGE_EXTENSIONS.map(extension => `.${extension}`),
      resolutionThumbnail: IMAGE_RESOLUTION.THUMBNAIL,
      turntableView: IMAGE_VIEW_ANGLE.VIEW360,
      imageSlideshowUrls: null,
      imageSlideshowActiveIndex: null,
      manageOptionsDisabledMessages: {
        DELETE: [
          'This version comes from a .BW file 🙁 Delete a colorway',
          'on the 3D file and upload it as a new version.'
        ]
      },
      showAssetBrowser: false,
      ICON_TYPE,
      thumbnailId: null,
      DROPDOWN_PLACEMENT
    }
  },

  computed: {
    ...mapGetters(['getItemDetail']),

    /**
     * @returns {object}
     */
    item () {
      return this.getItemDetail({ libraryType: this.libraryType })
    },

    /**
     * @returns {string}
     */
    libraryType () {
      return LIBRARY_TYPE.STYLE
    },

    /**
     * @returns {number|null}
     */
    optionId () {
      return this.option.id || null
    },

    /**
     * @returns {boolean}
     */
    isOptionEmpty () {
      return this.option.images.length === 0
    },

    /**
     * @returns {string[]}
     */
    tagsData () {
      const { mainColor, code } = this.option
      const tags = this.option.tags.map(tag => tag.text)

      return [mainColor, code, ...tags].filter(item => item !== null)
    },

    /**
     * @returns {object[]}
     */
    dropdownItems () {
      const { id: optionId } = this.option

      return [
        {
          show: this.canEdit,
          name: 'Add Images',
          disabledMessage: this.manageOptionsDisabledMessages.ADD,
          children: [
            {
              show: this.canEdit,
              name: 'From Renders',
              method: () => this.openAssetBrowser(optionId)
            },
            {
              show: this.canEdit,
              name: 'From Files',
              method: () => this.addImagesFromFileSystem(optionId)
            }
          ]
        },
        {
          show: this.canEdit && !this.isOptionEmpty,
          name: 'Download All Images',
          method: this.handleAllImagesDownload
        },
        {
          show: this.canEdit,
          name: 'Edit Data Manually',
          method: () => {
            this.$emit('update-option', { optionId })
          }
        },
        {
          show: this.canEdit,
          name: 'Delete',
          method: () => this.deleteOption(this.option),
          styles: [STYLE_TYPE.DANGER],
          disabled: !this.canManageOptions,
          disabledMessage: this.manageOptionsDisabledMessages.DELETE
        }
      ]
    },

    /**
     * @returns {boolean}
     */
    showImageSlideshow () {
      return this.imageSlideshowUrls !== null
    },

    /**
     * @returns {boolean}
     */
    isMainVersion () {
      return this.version.isMain
    },

    /**
     * @returns {string[]}
     */
    slideshowImages () {
      return this.option.slideshowImages.map(img => img.file_url).reverse()
    }
  },

  methods: {
    /**
     */
    openAssetBrowser () {
      this.showAssetBrowser = true
    },

    /**
     */
    closeAssetBrowser () {
      this.showAssetBrowser = false
    },

    /**
     * @param {object} payload
     */
    emitAddStyleOptionImages (payload) {
      this.$emit('add-images', payload)
    },

    /**
     * @param {string[]} imageUrls
     */
    addImagesFromRenders (imageUrls) {
      if (imageUrls.length > 0) {
        this.emitAddStyleOptionImages({
          styleId: this.item.id,
          versionId: this.version.id,
          optionId: this.option.id,
          urls: imageUrls
        })
      }

      this.closeAssetBrowser()
    },

    /**
     * @param {File[]} files
     */
    includeUploadedFiles (files) {
      this.emitAddStyleOptionImages({
        styleId: this.item.id,
        versionId: this.version.id,
        optionId: this.option.id,
        files
      })
    },

    /**
     */
    addImagesFromFileSystem () {
      Utils.fileUploader({
        extensions: this.thumbnailExtensions,
        callback: this.includeUploadedFiles
      })
    },

    /**
     * @param {number} imageIndex
     */
    setStyleThumbnail (imageIndex) {
      const thumbnailId = this.option.images[imageIndex].id

      this.$emit('update-image', {
        imageId: thumbnailId,
        versionId: this.version.id,
        optionId: this.option.id,
        styleId: this.version.styleId,
        isThumbnail: true
      })

      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.STYLE_IMAGE,
        action: TRACKER_EVENTS.SET_AS_MAIN,
        category: this.libraryType,
        value: thumbnailId,
        item: this.option,
        label: `${this.option.name} ${imageIndex + 1}`
      })

      this.thumbnailId = thumbnailId
    },

    /**
     * @param {object} image
     */
    deleteImage (image) {
      this.$emit('delete-image', {
        imageId: image.id,
        versionId: this.version.id,
        optionId: this.option.id,
        styleId: this.version.styleId
      })
    },

    /**
     */
    deleteOption () {
      const { id: optionId, name: optionName } = this.option

      this.$confirm(
        `This will permanently delete ${optionName}. Continue?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          this.$emit('delete-option', {
            optionId,
            optionName
          })
        })
        .catch(() => {})
    },

    /**
     * @param   {string} image
     *
     * @returns {string}
     */
    getImageFileName (image) {
      if (image.view_name === IMAGE_VIEW_ANGLE.VIEW360) {
        return '360-placeholder-image'
      }

      return image.file.split('/').pop().split('.')[0]
    },

    /**
     * @param {Event} event
     */
    loadFiles (event) {
      if (!this.canEdit) {
        return
      }

      this.$refs.optionDropzone.classList.remove('dragging')

      const validFiles = Utils.getValidFilesFromEvent(
        event,
        this.thumbnailExtensions
      )

      if (validFiles.length === 0) {
        return
      }

      this.emitAddStyleOptionImages({
        styleId: this.item.id,
        versionId: this.version.id,
        optionId: this.option.id,
        files: validFiles
      })
    },

    /**
     */
    dragOver () {
      if (!this.canEdit || this.isSorting) {
        return
      }

      this.$refs.optionDropzone.classList.add('dragging')
    },

    /**
     */
    dragLeave () {
      if (!this.canEdit) {
        return
      }

      this.$refs.optionDropzone.classList.remove('dragging')
    },

    /**
     * @param {object[]} images
     * @param {number}   imageIndex
     */
    openImageSlideshow (images, imageIndex) {
      this.imageSlideshowUrls = images.map(image => image.file_url)
      this.imageSlideshowActiveIndex = imageIndex
    },

    /**
     */
    closeImageSlideshow () {
      this.imageSlideshowUrls = null
      this.imageSlideshowActiveIndex = null
    },

    /**
     * @param {number} imageIndex
     */
    handleSlideshowChange (imageIndex) {
      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.STYLE_IMAGE,
        action: TRACKER_EVENTS.VIEWED,
        category: this.libraryType,
        value: this.option.images[imageIndex].id,
        item: this.option.images[imageIndex],
        label: `${this.option.name} image ${imageIndex + 1}`
      })
    },

    /**
     * @param {number} imageIndex
     */
    handleImageDownload (imageIndex) {
      this.triggerDownload(this.option.images[imageIndex].file_url)

      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.STYLE_IMAGE,
        action: TRACKER_EVENTS.DOWNLOADED,
        category: this.libraryType,
        value: this.option.images[imageIndex].id,
        item: this.option.images[imageIndex],
        label: this.option.name
      })
    },

    /**
     */
    handleAllImagesDownload () {
      this.triggerDownload(this.option.zipUrl)

      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.STYLE_COLORWAY,
        action: TRACKER_EVENTS.DOWNLOADED,
        category: this.libraryType,
        value: this.option.id,
        item: this.option,
        label: this.option.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$image-icon-size: spacing(2);
$option-image-icon-z-index-hover: 1;
$option-image-opacity: 0.4;
$image-loading-background-size: 70%;
$option-image-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

.option-item__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option-item__title-main {
  display: flex;
  align-items: center;
  line-height: 1;

  > * {
    margin-right: spacing(1);
  }

  /deep/ .el-dropdown {
    display: flex;
  }

  /deep/ .el-button {
    padding: 0;
  }
}

.option-item__tags {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(1);
  max-width: 100%;
}

.image-icon {
  @include flex-center;

  position: absolute;
  width: $image-icon-size;
  height: $image-icon-size;
  color: $blue;
  font-weight: $font-weight-bold;
  line-height: 1;
  text-align: center;
  opacity: 0;
  transition: opacity $transition;
}

.image-icon--loader {
  top: 0;
}

.image-icon--assign-thumbnail,
.image-icon--is-thumbnail {
  top: spacing(1/2);
  left: spacing(1/2);
}

.image-icon--is-thumbnail {
  align-items: start;
  color: $white;
  background: $blue;
  border-radius: $border-radius-round;
  opacity: 1;

  &:hover {
    opacity: 1;
  }
}

.image-icon--delete {
  top: spacing(1/2);
  right: spacing(1/2);
  color: $red;
}

.image-icon--download {
  right: spacing(1/2);
  bottom: spacing(1/2);
}

.image-list__image {
  width: 100%;
  height: 100%;

  .image-icon:hover {
    z-index: $option-image-icon-z-index-hover;
    opacity: $option-image-opacity;
  }

  .image-loading {
    position: absolute;
    top: 0;
    z-index: $z-index-layer-2;
    width: 100%;
    height: 100%;
    background:
      url('~@/assets/images/img-placeholder.svg') no-repeat center
      center;
    background-size: $image-loading-background-size;
    border-radius: $border-radius-s;
  }

  &:hover {
    box-shadow: $option-image-shadow;

    > img {
      opacity: $option-image-opacity;
    }

    .image-icon {
      opacity: 1;
    }

    .image-icon--assign-thumbnail:hover,
    .image-icon--delete:hover,
    .image-icon--download:hover {
      opacity: $option-image-opacity;
    }
  }
}

.dragging {
  background-color: $blue-light;
}
</style>

<style lang="scss">
.option-item__asset-browser {
  padding: 0;
}
</style>
