/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getUsers = state => state.users

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getAllUsers = state => state.allUsers

/**
 * @param   {object} state
 *
 * @returns {number}
 */
const getUsersCount = state => state.usersCount

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getMentionableUsers = state => state.mentionableUsers

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getUsersByTeam = state => state.usersByTeam

/**
 * @param   {object} state
 *
 * @returns {number}
 */
const getUsersByTeamCount = state => state.usersByTeamCount

/**
 * @param   {object} state
 *
 * @returns {number}
 */
const getUsersByVendorCount = state => state.usersByVendorCount

/**
 * @param   {object} state
 *
 * @returns {number}
 */
const getLimit = state => state.limit

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getTeams = state => state.teams

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getVendors = state => state.vendors

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getRegistrations = state => state.registrations

/**
 * @param   {object} state
 *
 * @returns {Array}
 */
const getUsersByVendor = state => state.usersByVendor

/**
 * @param   {object} state
 *
 * @returns {number}
 */
const getRegistrationsCount = state => state.registrationsCount

const getters = {
  getUsers,
  getAllUsers,
  getUsersCount,
  getTeams,
  getVendors,
  getUsersByTeam,
  getUsersByVendor,
  getLimit,
  getUsersByTeamCount,
  getUsersByVendorCount,
  getMentionableUsers,
  getRegistrationsCount,
  getRegistrations
}

export default getters
