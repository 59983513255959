import { deepFreeze } from '@/services/utils'

export const ROLE_TYPE = deepFreeze({
  CREATOR: {
    name: 'creator',
    displayName: 'Creator',
    description: 'Can view and edit everything within this team.'
  },
  VIEWER: {
    name: 'viewer',
    displayName: 'Viewer',
    description: 'Can only view everything within this team.'
  }
})

export const USER_ROLE = deepFreeze({
  PRODUCT_DESIGNER: {
    name: 'product-designer',
    displayName: 'Product Designer'
  },
  FIT_TECH: {
    name: 'fit-tech',
    displayName: 'Fit Technician'
  }
})

export const USER_ACTIVITY = deepFreeze({
  ACTIVE: {
    name: 'active',
    label: 'Active',
    value: true
  },
  INACTIVE: {
    name: 'inactive',
    label: 'Inactive',
    value: false
  }
})

export const TEAM_MEMBERSHIP = deepFreeze({
  MEMBER: {
    name: 'member',
    label: 'Member',
    value: false
  },
  TEAM_ADMIN: {
    name: 'team-admin',
    label: 'Team Admin',
    value: true
  }
})
