import { HTTP } from '@/services/axios'

/**
 * @returns {Promise<object>}
 */
const fetchNotificationsSettings = async () => {
  const response = await HTTP.get('notifications/settings')

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const updateNotificationsSettings = async payload => {
  const response = await HTTP.patch('notifications/settings', payload)

  return response.data
}

/**
 * @param   {number}   page
 * @param   {number}   limit
 *
 * @returns {object[]}
 */
const fetchNotifications = async (page = 1, limit = 6) => {
  const offset = page === 1 ? 0 : (page - 1) * limit

  const response = await HTTP.get('/notifications', {
    params: { limit, offset }
  })

  return response.data
}

/**
 * @param   {number} notificationId
 *
 * @returns {object}
 */
const readNotification = async notificationId => {
  const response = await HTTP.patch(`/notifications/${notificationId}`, {
    read: true
  })

  return response.data
}

export default {
  fetchNotificationsSettings,
  updateNotificationsSettings,
  fetchNotifications,
  readNotification
}
