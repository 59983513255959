export const ASSET_STATUS = {
  CREATED: {
    label: 'Created',
    value: 'created'
  },
  RESUBMIT: {
    label: 'Resubmit',
    value: 'resubmit'
  },
  CANCELLED: {
    label: 'Cancelled',
    value: 'cancelled'
  },
  REQUESTED: {
    label: 'Requested',
    value: 'requested'
  },
  APPROVED: {
    label: 'Approved',
    value: 'approved'
  },
  REVIEW: {
    label: 'Review',
    value: 'review'
  },
  APPROVED_FOR_DAM: {
    label: 'Approved for DAM',
    value: 'approved_for_dam'
  }
}

export const REASONS_FOR_CANCELLATION = [
  {
    id: 1,
    name: 'After Proto HO'
  },
  {
    id: 2,
    name: 'After System Freeze'
  },
  {
    id: 3,
    name: 'After Sales'
  },
  {
    id: 4,
    name: 'During Sales'
  },
  {
    id: 5,
    name: 'DCR'
  },
  {
    id: 6,
    name: 'Proto Review'
  },
  {
    id: 7,
    name: 'During Sales Prep'
  },
  {
    id: 8,
    name: 'Sketch Review'
  },
  {
    id: 9,
    name: 'User Input Error'
  }
]

export const REASONS_FOR_RESUBMITTING = [
  { id: 1, name: 'Simulation is not correct' },
  { id: 2, name: 'Naming of file is incorrect' },
  { id: 3, name: 'Naming of colorway/s are incorrect' },
  { id: 4, name: 'Both naming of file and colourway/s are incorrect' },
  { id: 5, name: 'Missing colorway/s' },
  { id: 6, name: 'Incorrect fabric and/or trims' },
  { id: 7, name: 'Image size does not meet requirements' },
  { id: 8, name: 'Incorrect artwork' },
  { id: 9, name: 'Missing artwork' },
  { id: 10, name: 'Multiple reasons, check comments' }
]
