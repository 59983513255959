import Vue from 'vue'
import { customAlphabet } from 'nanoid'
import { camelCase } from 'lodash'
import {
  TRACKER_QUERY_STRINGS,
  TRACKER_OBJECTS,
  TRACKER_EVENTS
} from '@/constants/tracker'

/**
 * @returns {string}
 */
export const getAppDomain = () => {
  const hostNameParts = window.location.hostname.split('.')
  hostNameParts.splice(0, 1)

  return hostNameParts.join('.')
}

/**
 */
const dateLocale = window.navigator.userLanguage || window.navigator.language

/**
 * @param   {string} date
 * @param   {object} options
 *
 * @returns {string}
 */
export const formatToLocaleDateLong = (date, options) => {
  const dateOptions = {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    day: 'numeric',
    year: 'numeric',
    month: 'long'
  }

  return new Date(date).toLocaleDateString(dateLocale, options || dateOptions)
}

/**
 * @param   {string} date
 *
 * @returns {string}
 */
export const formatToLocaleDateShort = date => {
  const dateOptions = {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    day: 'numeric',
    year: 'numeric',
    month: 'numeric'
  }

  return new Date(date).toLocaleDateString(dateLocale, dateOptions)
}

/**
 * @param   {string} string
 *
 * @returns {string}
 */
export const convertSnakeCaseToCamelCase = string => {
  return string.replace(/([_][a-z])/g, group =>
    group.toUpperCase().replace(/_/g, '')
  )
}

/**
 * @param   {object} snakeCasedObject
 *
 * @returns {object}
 */
export const convertObjectKeysToCamelCase = snakeCasedObject => {
  const camelCasedObject = {}

  Object.entries(snakeCasedObject || {}).forEach(([key, value]) => {
    camelCasedObject[convertSnakeCaseToCamelCase(key)] = value
  })

  return camelCasedObject
}

/**
 * @param   {object} filename
 *
 * @returns {string}
 */
export const getFileNameWithoutExtension = (filename = null) => {
  if (!filename) {
    return null
  }

  let name = filename
  const indexBeforeDot = name.lastIndexOf('.')
  name = indexBeforeDot >= 0 ? name.substring(0, indexBeforeDot) : name

  return name
}

/**
 * @param   {string} fileName
 *
 * @returns {string}
 */
export const getDescriptionFromFileName = fileName => {
  const fileNameArray = fileName.split('.')

  fileNameArray.pop()

  return fileNameArray.join('.') || ''
}

/**
 * @param   {string} fileName
 *
 * @returns {string}
 */
export const getExtensionFromFileName = fileName => {
  const fileNameArray = fileName.split('.')

  return fileNameArray.pop().toLowerCase()
}

/**
 * @param   {object} error
 * @param   {object} formConfiguration
 *
 * @returns {object}
 */
export const processError = (error, formConfiguration) => {
  if (!error) {
    return {
      step: formConfiguration.steps[0].id
    }
  }

  let stepIdToGo = null
  let errorField = null

  Object.keys(error).forEach(key => {
    const validKey = camelCase(key)

    if (!error[validKey]) {
      error[validKey] = error[key]
      delete error[key]
    }
  })

  formConfiguration.steps.forEach(step => {
    if (errorField === null || errorField === undefined) {
      errorField = step.fields.find(field => {
        return Object.keys(error).some(errorKey => errorKey === field.id)
      })
      stepIdToGo = step.id
    }
  })

  let errorMessage = error[errorField.id]

  if (Array.isArray(errorMessage)) {
    errorMessage = errorMessage[0]
  }

  return {
    step: stepIdToGo,
    [errorField.id]: errorMessage
  }
}

/**
 * @param   {object} response
 * @returns {string}
 */
export const showError = response => {
  const errorData = response.data
  let message
  const errorMessage = 'Check with the developers for more info'

  if (errorData) {
    if (Object.keys(errorData) && typeof errorData !== 'string') {
      const messages = []

      Object.entries(errorData).forEach(([key, value]) => {
        if (typeof value === 'string') {
          messages.push(`${value} (${key})`)
        } else if (Array.isArray(value)) {
          value.forEach(error => {
            if (error.message && error.data[key]) {
              messages.push(`${error.message} (${error.data[key]})`)
            } else {
              messages.push(`${error} (${key})`)
            }
          })
        }
      })

      message = messages.join(', ')
    } else {
      message = errorMessage
    }
  } else {
    if (response && response.data) {
      message = response.data.message || response.data.detail
    } else {
      message = errorMessage
    }
  }

  return message
}

/**
 * @param   {Event}    event
 * @param   {string[]} validExtensions
 *
 * @returns {File[]}
 */
export const getValidFilesFromEvent = (event, validExtensions) => {
  const files = Array.from(event.dataTransfer.files)

  return files.filter(file => {
    return validExtensions.some(extension => {
      return extension === `.${getExtensionFromFileName(file.name)}`
    })
  })
}

/**
 * @param   {Element} element
 * @param   {Element} container
 *
 * @returns {boolean}
 */
export const isInView = (element, container) => {
  const itemRect = element.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return (
    itemRect.top >= containerRect.top &&
    itemRect.bottom <= containerRect.bottom &&
    itemRect.bottom <= document.documentElement.clientHeight
  )
}

/**
 * @param {Element}          item
 * @param {Element}          container
 * @param {object | boolean} [options={behavior:'smooth',block:'end',inline:'nearest'}]
 */
export const scrollIfNotInView = (
  item,
  container,
  options = {
    behavior: 'smooth',
    block: 'end',
    inline: 'nearest'
  }
) => {
  if (!Utils.isInView(item, container)) {
    item.scrollIntoView(options)
  }
}

/**
 * @param {object}   payload
 * @param {Array}    payload.extensions
 * @param {Function} payload.callback
 */
export const fileUploader = ({ extensions, callback }) => {
  const fakeInput = document.createElement('input')
  fakeInput.setAttribute('type', 'file')
  fakeInput.setAttribute('multiple', true)

  if (extensions) {
    fakeInput.setAttribute('accept', extensions.join(','))
  }

  if (callback && typeof callback === 'function') {
    fakeInput.onchange = () => {
      const files = Array.from(fakeInput.files)
      callback(files)
    }
  }

  fakeInput.click()
}

/**
 * @param   {string}          src
 * @param   {AbortController} abortController
 *
 * @throws {DOMException}
 *
 * @returns {Image}
 */
export const loadImage = async (src, abortController = null) => {
  if (
    abortController &&
    abortController.signal &&
    abortController.signal.aborted
  ) {
    throw new DOMException('Aborted', 'AbortError')
  }

  const image = new Image()
  image.src = src
  /**
   */
  const abortHandler = () => {
    // this actually stops the image loading
    image.src = ''
  }

  if (abortController && abortController.signal) {
    abortController.signal.addEventListener('abort', abortHandler)
  }

  try {
    await image.decode()
  } catch (error) {
    // image data is corrupted, don't use it
    return null
  }

  return image
}

/**
 * @returns {number}
 */
export const generateId = () => {
  // ids should be integers
  return parseInt(customAlphabet('1234567890', 20)())
}

/**
 * @param   {object} object
 *
 * @returns {object}
 */
export const deepFreeze = object => {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object)

  // Freeze properties before freezing self

  for (const name of propNames) {
    const value = object[name]

    if (value && typeof value === 'object') {
      deepFreeze(value)
    }
  }

  return Object.freeze(object)
}

/**
 * @param {UserShape} user
 * @param {string}    $hubApiBaseUrl
 * @param {string}    $rendersApiBaseUrl
 */
export const logout = (user, $hubApiBaseUrl, $rendersApiBaseUrl) => {
  Vue.prototype.$tracking.trackEvent({
    object: TRACKER_OBJECTS.USER,
    action: TRACKER_EVENTS.LOGGED_OUT,
    item: user
  })

  const hubAPILogoutUrl = new URL(`${$hubApiBaseUrl}id_auth/logout/`)
  hubAPILogoutUrl.searchParams.set('state', window.location.href)

  const rendersAPILogoutUrl = new URL(`${$rendersApiBaseUrl}id_auth/logout/`)
  rendersAPILogoutUrl.searchParams.set('state', hubAPILogoutUrl.href)

  localStorage.removeItem('activeWorkspace')
  window.location.assign(rendersAPILogoutUrl.href)
}

/**
 * Tracks login action if a specific hash exists on the URL
 *
 * @param {UserShape} user
 * @param {string}    platform
 */
export const trackLogin = (user, platform) => {
  const currentUrl = new URL(document.location)

  if (currentUrl.hash === TRACKER_QUERY_STRINGS.LOGIN) {
    Vue.prototype.$tracking.trackEvent({
      object: TRACKER_OBJECTS.USER,
      action: TRACKER_EVENTS.LOGGED_IN,
      item: user,
      platform
    })

    // remove login hash without refreshing the app
    currentUrl.hash = ''
    window.location.replace(currentUrl.toString())
  }
}

/**
 * @param {object} response
 * @param {string} team
 */
export const downloadExcelFile = (response, team = null) => {
  const url = URL.createObjectURL(
    new Blob([response.data], {
      type: 'application/vnd.ms-excel'
    })
  )
  const link = document.createElement('a')
  link.href = url
  const fileName = team ? `Hub3D-Users-${team}.xlsx` : 'Hub3D-Users.xlsx'
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

const Utils = {
  getAppDomain,
  formatToLocaleDateLong,
  formatToLocaleDateShort,
  convertSnakeCaseToCamelCase,
  convertObjectKeysToCamelCase,
  getFileNameWithoutExtension,
  getDescriptionFromFileName,
  getExtensionFromFileName,
  getValidFilesFromEvent,
  processError,
  showError,
  isInView,
  scrollIfNotInView,
  fileUploader,
  loadImage,
  generateId,
  deepFreeze,
  logout,
  trackLogin
}

export default Utils
