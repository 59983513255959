import serverAPI from '@/services/serverAPI'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { FILTER_TYPE } from '@/constants/filterType'
import { ASSET_STATUS } from '@/constants/assetStatuses'

// ////////////////////////////////////////////////////
// Actions
// ////////////////////////////////////////////////////

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {string}        payload.filterGroupName
 * @param {number[]}      payload.filterIds
 * @param {string}        payload.libraryType
 */
const setAppliedFilter = (
  { commit, getters },
  { filterGroupName, filterIds, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  commit('setAppliedFilter', { filterGroupName, filterIds, libraryType })
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {string}        payload.libraryType
 */
const fetchAvailableFilters = async (
  { commit, getters },
  { libraryType } = {}
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const activeWorkspace = getters.getActiveWorkspaceCode
  const userGroups = getters.getCognitoUserData.permissions.map(
    permission => permission.group
  )
  let filters

  if (libraryType === LIBRARY_TYPE.STYLE) {
    const results = await Promise.all([
      serverAPI.fetchSeasons(),
      serverAPI.fetchStylePrices(),
      serverAPI.fetchFilters(libraryType, activeWorkspace)
    ])

    const mappedStyleFilters = results[2].reduce((previous, current) => {
      const filterValues = current.values.map(value => {
        if (current.id === FILTER_TYPE.ASSET_STATUS) {
          return {
            id: value,
            name: value,
            label: ASSET_STATUS[value.toUpperCase()].label
          }
        }

        return { id: value, name: value, label: value }
      })

      return {
        ...previous,
        [current.id === FILTER_TYPE.ASSET_STATUS ? current.id : current.name]:
          filterValues
      }
    }, {})

    const mappedSeasonFilters = results[0].map(filter => {
      return {
        label: filter.name,
        ...filter
      }
    })

    filters = {
      [FILTER_TYPE.GROUP_ID]: getFilterGroupSorted(userGroups),
      [FILTER_TYPE.SEASON_ID]: getFilterGroupSorted(mappedSeasonFilters),
      [FILTER_TYPE.PRODUCT_GROUP]:
        mappedStyleFilters[FILTER_TYPE.PRODUCT_GROUP],
      [FILTER_TYPE.ASSET_STATUS]: mappedStyleFilters[FILTER_TYPE.ASSET_STATUS],
      [FILTER_TYPE.PRICE_ID]: results[1],
      ...mappedStyleFilters
    }

    commit('setStylesAttributes', mappedStyleFilters)
  } else if (libraryType === LIBRARY_TYPE.OPTION) {
    const results = await Promise.all([serverAPI.fetchSeasons()])
    filters = {
      [FILTER_TYPE.GROUP_ID]: getFilterGroupSorted(userGroups),
      [FILTER_TYPE.SEASON_ID]: getFilterGroupSorted(results[0])
    }
  } else {
    filters = await serverAPI.fetchFilters(libraryType, activeWorkspace)

    if (filters[FILTER_TYPE.DIVISION_ID]) {
      filters[FILTER_TYPE.DIVISION_ID] = getFilterGroupSorted(userGroups)
    }
  }

  commit('setAvailableFilters', { filters, libraryType })
}

/**
 * @param {Store}         context
 * @param {Store.getters} context.getters
 * @param {Store.commit}  context.commit
 * @param {object}        payload
 * @param {number}        payload.groupId
 * @param {number}        payload.seasonId
 * @param {boolean}       payload.isOptionForm
 * @param {string}        payload.libraryType
 */
const fetchTags = async (
  { getters, commit },
  { groupId, seasonId, isOptionForm, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  const {
    [FILTER_TYPE.GROUP_ID]: groupIds,
    [FILTER_TYPE.SEASON_ID]: seasonIds
  } = getters.getAppliedFilters({ libraryType })
  groupId = groupId || (groupIds && groupIds[0])
  seasonId = seasonId || (seasonIds && seasonIds[0])

  const tags = await serverAPI.fetchTags({ groupId, seasonId })
  const tagsFormatted = tags.map(tag => ({
    ...tag,
    name: tag.text
  }))

  if (isOptionForm) {
    commit('setTags', {
      tags: tagsFormatted,
      libraryType
    })
  } else {
    const filtersToReset = [FILTER_TYPE.TAGS]
    const availableFilters = getters.getAvailableFilters({ libraryType })
    const filters = {
      ...availableFilters,
      [FILTER_TYPE.TAGS]: getFilterGroupSorted(tagsFormatted)
    }
    commit('resetAppliedFilters', { filtersToReset, libraryType })
    commit('setAvailableFilters', {
      filters,
      libraryType
    })
  }
}

/**
 * @param   {object[]} filterGroup
 *
 * @returns {object[]}
 */
const getFilterGroupSorted = filterGroup => {
  return filterGroup.sort((filterA, filterB) => {
    const nameA = filterA.name.toUpperCase()
    const nameB = filterB.name.toUpperCase()

    if (nameA < nameB) {
      return -1
    } else if (nameA > nameB) {
      return 1
    } else {
      return 0
    }
  })
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {string[]}      payload.filtersToReset
 * @param {string[]}      payload.filtersToKeep
 * @param {string}        payload.libraryType
 */
const resetAppliedFilters = (
  { commit, getters },
  { filtersToReset = null, filtersToKeep = null, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  commit('resetAppliedFilters', { filtersToReset, filtersToKeep, libraryType })
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {string}        payload.searchQuery
 * @param {string}        payload.libraryType
 */
const setSearch = (
  { commit, getters },
  { searchQuery = '', libraryType } = {}
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  commit('setSearch', { searchQuery, libraryType })
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {string}        payload.searchQuery
 * @param {string}        payload.libraryType
 */
const resetSearch = (
  { commit, getters },
  { searchQuery = '', libraryType } = {}
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  commit('setSearch', { searchQuery, libraryType })
}

// ////////////////////////////////////////////////////
// Build object
// ////////////////////////////////////////////////////

const actions = {
  setAppliedFilter,
  fetchAvailableFilters,
  fetchTags,
  resetAppliedFilters,
  setSearch,
  resetSearch
}

export default actions
