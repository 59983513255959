var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('StitchDialog',_vm._b({class:[
    'form-generator',
    ("form-generator--step" + _vm.activeStep),
    {
      'form-generator--fullscreen': _vm.modalOptions && _vm.modalOptions.fullscreen,
      'form-generator--with-steps': _vm.showStepBar
    }
  ],attrs:{"visible":_vm.showDialog,"append-to-body":""},on:{"update:visible":function($event){_vm.showDialog=$event},"closed":_vm.onClose}},'StitchDialog',_vm.modalOptions,false),[_c('div',{staticClass:"form-generator__title-area",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"form-generator__title-line"},[(_vm.currentStepMetaData.headerLink)?[_c('ElementButton',{staticClass:"form-generator__title-header-link",attrs:{"type":"text"},on:{"click":_vm.handleHeaderLinkClick}},[_vm._v(" "+_vm._s(_vm.currentStepMetaData.headerLink.text)+" ")])]:_vm._e(),_c('div',{staticClass:"form-generator__title"},[_vm._v(" "+_vm._s(_vm.currentStepMetaData.title)+" ")])],2),(_vm.currentStepMetaData.notes)?_c('div',{staticClass:"form-generator__notes"},[_c('InputNotes',{attrs:{"notes":_vm.currentStepMetaData.notes}})],1):_vm._e()]),(_vm.formData[_vm.currentStepIndex])?_c('ElementForm',{ref:"formGenerator",class:[
      'form-generator__content',
      {
        'form-generator__content--split': _vm.shouldBeSplit,
        'form-generator__content--empty': _vm.currentStepFields.length === 0
      }
    ],attrs:{"model":_vm.formData[_vm.currentStepIndex],"label-position":"top"}},[_vm._l((_vm.currentStepFields),function(field,key){return [(_vm.isVisible(field))?_c(_vm.TYPE_MAP[field.type],{key:(_vm.currentStepIndex + "-" + key),tag:"component",attrs:{"input-key":field.id,"parameters":field,"is-disabled":_vm.isDisabledMap[field.id],"default-value":_vm.getDefaultValue(field.id, field.defaultValue, field.forcedValue),"custom-error":_vm.getCustomError(field.id),"options":_vm.currentOptions(field)},on:{"set-data":_vm.setData,"on-close":_vm.onClose}}):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"form-generator__confirm-button",attrs:{"slot":"footer"},slot:"footer"},[_c('ElementButton',{attrs:{"type":"primary","plain":"","loading":_vm.isValidating,"disabled":!_vm.areRequiredFieldsFilled},on:{"click":_vm.validateCurrentStep}},[_vm._v(" "+_vm._s(_vm.currentStepMetaData.submitText)+" ")]),(_vm.currentStepMetaData.skip)?_c('ElementLink',{staticClass:"skip-button",attrs:{"type":"primary","underline":false},on:{"click":_vm.onSkip}},[_vm._v(" "+_vm._s(_vm.currentStepMetaData.skip.name)+" ")]):_vm._e(),(_vm.showStepBar)?_c('div',{staticClass:"form-generator__step-bar"},[_vm._v(" Step "+_vm._s(_vm.activeStep)+"/"+_vm._s(_vm.totalSteps)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }